// @ts-nocheck

export const CONSENT_GRANTED = {
    'ad_storage': 'granted',
    'analytics_storage': 'granted',
    'personalization_storage': 'granted',
    'functionality_storage': 'granted',
    'security_storage': 'granted',
    'ad_user_data': 'granted',
    'ad_personalization': 'granted'
}

export const CONSENT_DENIED = {
    'ad_storage': 'denied',
    'analytics_storage': 'denied',
    'personalization_storage': 'denied',
    'functionality_storage': 'denied',
    'security_storage': 'granted',
    'ad_user_data': 'denied',
    'ad_personalization': 'denied'
}

//gtag.js
export function gtag (...arg: any[]): void {
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push(arguments)
}

export function initGtag (): void {
    // Set default consent state before any GTM/GA tag execution
    localStorage.getItem('consentMode')
        ? gtag('consent', 'default', CONSENT_GRANTED)
        : gtag('consent', 'default', CONSENT_DENIED)
}